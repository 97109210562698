<template>
  <div class="staff" @click="showStaffDetail" role="button">
    <div class="staff-left">
      <v-avatar class="avator" v-if="data.image||data.photo">
        <!-- <img
          :src="'files/'+(data.image?data.image:data.photo)"
          :alt="data.name"
        > -->
        <newImg :fileUrl="'files/' + (data.image ? data.image : data.photo)"></newImg>
      </v-avatar>
      <div v-else>
        <v-avatar color="#707070" class="avator" v-if="data.firstName" style="color:#fff">
          <span class="white--text" style="font-size: 16px">
          {{ data.firstName[0] + ' ' + data.lastName[0] }}
          </span>
        </v-avatar>
        <v-avatar color="#707070" class="avator" v-else>
            <span class="white--text" style="font-size: 16px">
          {{ data.name[0] }}
          </span>
        </v-avatar>
      </div>
      <div class="rightContent">
        <div class="name">{{ data.name }}</div>
        <div>{{ data.title }}</div>
        <div>{{ data.company }}</div>
      </div>
    </div>

    <div class="checkbox" v-show="showCheckbox">
      <v-checkbox
        v-model="data.chosen"
        @change="selectPa"
      ></v-checkbox>
      <!--<v-checkbox-->
      <!--  v-model="checkbox"-->
      <!--  @change="selectPa"-->
      <!--&gt;</v-checkbox>-->
    </div>
    <div class="cancel" v-show="showCancel">
      <i class="iconfont icon-chahao" @click="cancel"></i>
    </div>
  </div>
</template>

<script>
import newImg from "@/views/dashboard/componentsHa/img";
export default {
  name: "staff",
  props: {
    // 搜索出来的人员
    data: Object,
    // 右侧勾选框
    showCheckbox: {
      type: Boolean,
      default: false
    },
    // 右侧删除图标
    showCancel: {
      type: Boolean,
      default: false
    }
  },
  components: {
    newImg
  },
  data() {
    return {
      checkbox: false
    }
  },
  methods: {
    showStaffDetail() {
      this.$emit("showStaffDetail", this.data);
    },
    // 勾选与会人员
    selectPa(data) {
      this.$emit("selectPa", this.data);
    },
    cancel(data) {
      this.$emit("cancel", this.data);
    }
  },
}
</script>

<style scoped lang="scss">
.staff {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:5px;
  .staff-left {
    display: flex;
    align-items: center;
    .avator {
      margin-right: 1rem;
    }

    .rightContent {
      text-align: left;
      height:auto;
      .name {
        font-weight: bold;
      }
    }
  }

  .checkbox {
    float: right;
  }
}
</style>
